import Cookies from 'js-cookie';
class SurveyFlow {
  constructor(opts) {
    this.surveyId = opts.surveyId;
    this.weeks = 51;
    this.biWeeks = 25;
    this.months = 11;
    this.quarters = 3;
    this.years = 1;
  }
  teamSubmitListener = () => {
    $('#next-to-timing').on('click', (e) => {
      e.target.textContent = 'Saving...';
      if (sessionStorage.getItem('option') === "tmx360_card") {
        $('#360_new_survey').submit();
      } else {
        $('#new_survey').submit();
      }
    });
  };

  timingSubmitListener = () => {
    $('#next-to-invitations').on('click', (e) => {
      e.preventDefault();

      const startDate = new Date($('#survey_start_date').val());
      const current = new Date();
      startDate.setHours(current.getHours());
      startDate.setMinutes(current.getMinutes());
      startDate.setSeconds(current.getSeconds());

      const period = $('#recurring_modal').find('select').val();
      const closeDate = this.invitationDateCalculation(period, startDate);
      this.saveStartDate(startDate.toUTCString(), closeDate.toUTCString());
      this.saveRecurringData();
      location.href = `/surveys/invitations/${this.surveyId}`;
    });
  };

  nextToTimingListener = () => {
    $('#survey-topic, #survey-time-frame, #survey-time-frame-360-tmx, #survey-topic-360-tmx').on('change', (e) => {
      let emptyForm

      if (e.currentTarget.id === 'survey-topic-360-tmx' || e.currentTarget.id === 'survey-time-frame-360-tmx' ) {
        emptyForm =  _.isEmpty($('#survey-topic-360-tmx').val()) && _.isEmpty($('#survey-time-frame-360-tmx').val())
      } else {
        emptyForm = (_.isEmpty($('#survey-topic').val()) && _.isEmpty($('#survey-time-frame').val()))
      }

      if ( emptyForm ) {
        $('#next-to-timing').addClass('disabled');
      } else {
        $('#next-to-timing').removeClass('disabled');
      }

      this.addTheLastPrefix();
    });
  };

  addTheLastPrefix = () => {
    const defaultTimeFrames = ["week", "month", "quarter", "year"];
    const defaultTimeFrame = defaultTimeFrames.includes($('#survey-time-frame').val());

    if (defaultTimeFrame) {
      $('.past').removeClass('hidden');
    } else {
      $('.past').addClass('hidden');
    }
  };

  nextToReviewListener = () => {
    $('input[name="survey[team_members_ids][]"], input[name="team_member_group"], input[name="team_member_all"]').on('change', () => {
      if ($('input[name="survey[team_members_ids][]"]:checked').length > 0) {
        $('#next-to-review').removeClass('disabled');
      } else {
        $('#next-to-review').addClass('disabled');
      }
    });
  };

  saveStartDate = (startDate, closeDate) => {
    Cookies.set('start_date', startDate)
    Cookies.set('close_date', closeDate)
  };

  saveRecurringData = () => {
    Cookies.set('recurring_params_value', $('#recurring_modal').find('#recurring_value').val());
    Cookies.set('recurring_params_period', $('#recurring_modal').find('select').val());
  };

  saveTeamMembers = (ids, copy_me) => {
    ids = ids || {};
    copy_me = copy_me || false;

    Cookies.set('copy_me', copy_me);

    $.ajax({
      url: `/surveys/update_team_members/${this.surveyId}`,
      dataType: 'json',
      type: 'get',
      data: { survey: {team_members_ids: ids, copy_me: copy_me }},
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    });
  };

  clearLocalStorage = () => {
    const self = this;

    $('#cancel').on('click', () => {
      self.clearCookiesAndSession();
      location.href = '/main';
    });
  };

  sendLeaderSurveyListener = () => {
    $('#send-to-leader').on('click', () => {
      this.sendSurveyToLeader();
    });
  };

  sendSurveyToLeader = () => {
    $.ajax({
      url: `/leader_survey/${this.surveyId}`,
      dataType: 'json',
      type: 'get',
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
      }
    }).done(
      $('#send-to-leader').text('Sent').addClass('disabled')
    );
  };
  backButtonListener = () => {
    $('#back-to-type').on('click', () => {
      location.href = '/surveys/type';
    });

    $('#back-to-timing, #back-to-invitations').on('click', () => {
      window.history.back();
    });
  };

  personaliseInvitation = (defaultMessagePreview) => {
    const personalizeMessageInput = $('#add_text_modal').find('#personalize_message_text');
    personalizeMessageInput
      .on('keyup', () => this.updateMessageText(defaultMessagePreview))
      .bind('paste', () => {
        setTimeout(this.updateMessageText(defaultMessagePreview), 100);
      });

    return this;
  };

  randomTextShowPage = () => {
    const textScenarios = [ "You did it! Take a breather...", "Keep calm and build experiences",
      "High five!", "Nice work!", "All done. The future is yours."];
    const randomText = textScenarios[textScenarios.length * Math.random() | 0];
    $('.random-text').text(randomText);
  };

  clearCookiesAndSession = () => {
    Cookies.remove('recurring_params_value')
    Cookies.remove('recurring_params_period')
    Cookies.remove('copy_me')
    Cookies.remove('start_date')
    Cookies.remove('close_date')
    sessionStorage.removeItem('option');
    sessionStorage.removeItem('recurrence');
  }

  updateMessageText = (defaultMessagePreview) => {
    const message = $('#personalize_message_text').val();
    if (message == '') {
      $('#add_text_modal').find('.pre-head').addClass('placeholder').html(defaultMessagePreview)
    } else {
      $('#add_text_modal').find('.pre-head').removeClass('placeholder').html(message.replace(/\n/g, "<br />"))
    }
  };

  recurringText = (title, defaultText, text) => {
    let $recurrence_container_title = $('#recurrence-container-title');
    let $recurrence_default = $('#recurrence-default');
    let $recurrence_text = $('#recurrence-text');

    $recurrence_container_title.text(title);
    $recurrence_default.text(defaultText);
    $recurrence_text.text(text);
  };

  recurrenceCardListener = () => {
    $('select[name="recurring[period]"], input[name="recurring[value]"]').on('change', () => {
      const period = $('#recurring_modal').find('select').val();
      const weeklyMessage = `Get feedback every week. These surveys will be sent the same day each week.`;
      const biWeeklyMessage = `Get feedback every other week. These surveys will be sent the same day each other week.`;
      const monthlyMessage = `Get feedback every month. These surveys will be sent the same day each month.`;
      const quarterlyMessage = `Get feedback every quarter. These surveys will be sent the same day each quarter.`
      const annuallyMessage = `Get feedback every year.`;

      switch (period) {
        case 'weekly':
          return this.recurringText('Weekly', '', weeklyMessage);
        case 'bi_weekly':
          return this.recurringText('Bi-Weekly', '', biWeeklyMessage);
        case 'monthly':
          return this.recurringText('Monthly', '', monthlyMessage);
        case 'quarterly':
          return this.recurringText('Quarterly', '', quarterlyMessage);
        case 'annually':
          return this.recurringText('Annually', '', annuallyMessage);
      }
    })
  };

  recurringPeriodListener = () => {
    $('#recurring_period').on('change', () => {
      let period = $('#recurring_period').val();
      let $recurEl = $('#recurring_value');

      if(period == 'weekly') { $recurEl.val(this.weeks); }
      if(period == 'bi_weekly') { $recurEl.val(this.biWeeks); }
      if(period == 'monthly') { $recurEl.val(this.months); }
      if(period == 'quarterly') { $recurEl.val(this.quarters); }
      if(period == 'annually') { $recurEl.val(this.years); }
    })
  };

  recurringValueListener = () => {
    let $recurEl = $('#recurring_value');

    $recurEl.on('keyup change', () => {
      let val = $recurEl.val();
      let period = $('#recurring_period').val();

      let isFailedValidation = (val) => {
        return ( isNaN(parseInt(val)) || val == 0 || !_.isNumber(parseInt(val)) );
      };

      if(isFailedValidation(val)) {
        $recurEl.val(1);
      }

      if(period == 'weekly' && val > this.weeks) { $recurEl.val(this.weeks); }
      if(period == 'bi_weekly' && val > this.biWeeks) { $recurEl.val(this.biWeeks); }
      if(period == 'monthly' && val > this.months) { $recurEl.val(this.months); }
      if(period == 'quarterly' && val > this.quarters) { $recurEl.val(this.quarters); }
      if(period == 'annually' && val > this.years) { $recurEl.val(this.years); }
    });

    $('#recur-more').on("click", () => {
      $recurEl.val(parseFloat($recurEl.val()) + 1);
      $recurEl.trigger('change');
    });

    $('#recur-less').on("click", () => {
      $recurEl.val() > 1 ? $recurEl.val(parseFloat($recurEl.val()) - 1) : $recurEl.val(1);
    });
  };

  recurringPeriodForCalculation = () => {
    $('select[name="recurring[period]"]').change(() => {
      this.calculateStartDate();
    })
  };

  nextToInvitationsListener = () => {
    if ($('#survey_start_date').val() === '') {
      $('#next-to-invitations').addClass('disabled');
      $('#date_modal').addClass('hidden');
    } else {
      $('#next-to-invitations').removeClass('disabled');
      $('#date_modal').removeClass('hidden');
    }
  }

  closeDateCalculation = (startDate, daysBeforeInvitation) => {
    const invitationDate = new Date(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate() + daysBeforeInvitation,
        startDate.getHours(),
        startDate.getMinutes(),
        startDate.getSeconds()
    );

    if (invitationDate < new Date()) {
      return new Date();
    }

    return invitationDate;
  }

  invitationDateCalculation = (period, startDate) => {
    let invitationDate = null;

    switch (period) {
      case 'weekly':
      case 'bi_weekly':
        invitationDate = this.closeDateCalculation(startDate, 2);
        break;
      case 'monthly':
      case 'bi_monthly':
      case 'quarterly':
        invitationDate = this.closeDateCalculation(startDate, 5);
        break;
      case 'annually':
        invitationDate = this.closeDateCalculation(startDate, 14);
        break;
      default:
        invitationDate = this.closeDateCalculation(startDate, 7);
        break;
    }

    return invitationDate;
  }

  calculateStartDate = () => {
    const startDate = new Date($('#survey_start_date').val());
    const period = $('#recurring_modal').find('select').val();

    this.nextToInvitationsListener();
    const date = this.invitationDateCalculation(period, startDate);
    $('#calculated-start-date').text(date.toDateString());
  };

  teamMembersHandler = () => {
    $('.survey_team_members, .survey_team_members_group, #team_member_all, #copy_me').change(() => {
      const $surveyTeamMembers = $('.survey_team_members');
      const $checkedTeamMembers = $('.survey_team_members:checked');
      const copyMe = $('#copy_me:checked').length > 0;
      const check = ($surveyTeamMembers.filter(":checked").length == $surveyTeamMembers.length);
      const teamMembersIds = [];

    $('#team_member_all').prop("checked", check);
    $('#link_to_add_text').toggleClass('disabled', $surveyTeamMembers.filter(":checked").length == 0);

      $checkedTeamMembers.each((i, obj) => {
        teamMembersIds.push($(obj).val());
      });
      this.saveTeamMembers(teamMembersIds, copyMe);
    });

    return this;
  };

  allTeamMembersHandler = () => {
    $('#team_member_all').click(e => {
      _.each($('.survey_team_members'), (currentCheckbox, i) => {
        if (!$(currentCheckbox).attr('disabled'))
          $(currentCheckbox).prop('checked', e.target.checked);
      });
      $('.survey_team_members_group').prop('checked', e.target.checked);
    });

    return this;
  };

  teamMembersGroupHandler = () => {
    $('.survey_team_members_group').click(e => {
      const groupVal = $(e.target).val();
      const isGroupChecked = $(e.target).is(":checked");

      _.each($('.survey_team_members'), (currentCheckbox, i) => {
        const currentGroups = $(currentCheckbox).data('group');

        if (_.contains(currentGroups, groupVal) && !$(currentCheckbox).attr('disabled'))
          $(currentCheckbox).prop('checked', isGroupChecked);
      });
    });

    return this;
  };

  foldTeamMembersHandler = () => {
    $('.fold-team-members > i').click(e => {
      e.stopPropagation();
      const $survey = $('.survey-team-members');

      $survey.is(":visible") ? $survey.hide() : $survey.show();
      $('.fold-team-members>i').toggleClass('fa-minus').toggleClass('fa-plus');
    });

    return this;
  };

  openCustomCardListener = () => {
    $('#custom_card').on('click', () => {
      const currentCard = sessionStorage.getItem('option');
      $('.choice-page').attr("style", "display: none !important");

      if (currentCard === 'tmx360_card') {
        $('#tmx360-builder').show();
      } else {
        $('#question-builder').show();
      }
    });
  };

  questionPreviewCardListener = () => {
    $('#open-preview-question').on('click', (e) => {
      e.target.style.display = 'none';
      $('#new_survey').hide();
      $('#preview-question').show();
      $('.choice-page').attr("style", "display: none !important");
    });
  };

  editSelectedOption = () => {
    $('.fa-pencil-alt').on('click', (e) => {
      const $select = $(e.target).hasClass('topic') ? $('#survey-topic') : $('#survey-time-frame');
      const selectedText = $select.select2("data")[0]['text']

      if(selectedText === "") { return; }

      $select.select2('open');
      $("input.select2-search__field").val(selectedText);
    });
  }

  closeCustomCardListener = () => {
    $('#back-to-presets, #back-to').on('click', () => {
      $('#question-builder').hide();
      $('#tmx360-builder').hide();
      $('.choice-page').attr("style", "display: flex !important");
    });
  };

  initCalendar = () => {
    $('#survey_start_date').datepicker({
      inline: true,
      minDate: new Date(),
      showOtherMonths: true,
      dayNamesMin: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      onSelect: () => {
         this.calculateStartDate();
      }
    });
    $('.-current-').click();
  };

  initTopicSelect = () => {
    if ($('#admin-form-marker').length === 0) {
      $('#survey-topic, #survey-topic-360-tmx').select2({
        placeholder: "Select a Topic",
        tags: true,
        multiple: false,
        selectOnClose: true,
      });
    }
  };

  initTimeFrameSelect = () => {
    $('#survey-time-frame, #survey-time-frame-360-tmx').select2({
      placeholder: "Time Period",
      tags: true,
      multiple: false,
      selectOnClose: true
    });
  };

  recurrenceCardsListener = (selector) => {
    $(selector).on('click', () => {
      let recurrent = selector === '#recurring_card' ? 'true' : 'false'
      $('#onetime_card, #recurring_card').removeClass('selected-card');
      $(selector).toggleClass('selected-card');
      sessionStorage.setItem('recurrence', recurrent);
      $('#next-to-team').removeClass('disabled');
    });
  }

  chooseCard = () => {
    this.recurrenceCardsListener('#recurring_card');
    this.recurrenceCardsListener('#onetime_card');

    $('#team_card').on('click', (e) => {
      $('#leader_card, #other_card, #value_card, #tmx360_card, #custom_card').removeClass('selected-card');
      $('#team_card').toggleClass('selected-card');
      sessionStorage.setItem('option', e.target.id);
      $('#survey_preset_type, #survey_preset_type_360').val('team_rss');
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
    });
    $('#leader_card').on('click', (e) => {
      $('#team_card, #other_card, #value_card, #tmx360_card, #custom_card').removeClass('selected-card');
      $('#leader_card').toggleClass('selected-card');
      sessionStorage.setItem('option', e.target.id);
      $('#survey_preset_type, #survey_preset_type_360').val('leader_rss');
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
    });
    $('#other_card').on('click', (e) => {
      $('#team_card, #leader_card, #value_card, #tmx360_card, #custom_card').removeClass('selected-card');
      $('#other_card').toggleClass('selected-card');
      sessionStorage.setItem('option', e.target.id);
      $('#survey_preset_type, #survey_preset_type_360').val('cross_team_rss');
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
      $('.another-name-field').focus();
    });
    $('#value_card').on('click', (e) => {
      $('#team_card, #leader_card, #other_card, #tmx360_card, #custom_card').removeClass('selected-card');
      $('#value_card').toggleClass('selected-card');
      sessionStorage.setItem('option', e.target.id);
      $('#survey_preset_type, #survey_preset_type_360').val('value_rss');
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
    });
    $('#tmx360_card').on('click', (e) => {
      $('#team_card, #leader_card, #other_card, #value_card, #custom_card').removeClass('selected-card');
      $('#tmx360_card').toggleClass('selected-card');
      sessionStorage.setItem('option', e.target.id);
      $('#survey_preset_type, #survey_preset_type_360').val('tmx360_rss');
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
    });
    $('#custom_card').on('click', () => {
      let presetTypeValue = $('#survey_preset_type').val();
      let presetType = presetTypeValue === "" ? 'custom_rss' : presetTypeValue;
      $('#survey_preset_type, #survey_preset_type_360').val(presetType);
      $('#survey_recurring, #survey_recurring_360').val(sessionStorage.getItem('recurrence'));
    });
  }

  initSurveyIndexDataTable  = () => {
    $('#survey-table').dataTable({
      responsive: true,
      iDisplayLength: 25,
      lengthMenu: [[10, 15, 25, 50, -1], [10, 15, 25, 50, "All"]],
      pagingType: 'simple',
      info: true
    });
  };
}
export default SurveyFlow